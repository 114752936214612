import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"
import starberryLogo from "../../images/starberry-logo.svg"
import ipavLogo from "../../images/ipav-logo.svg"
import ricsLogo from "../../images/rics-logo.svg"
import scsLogo from "../../images/scs-logo.svg"
import "./footer.scss"
import FooterMenu from "./FooterMenu"
import GetURL from "@Components/common/site/get-url"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"

import StickyHome from "../StickyHome/StickyHome"

const Footer = () => {
  const year = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        menuFooter(publicationState: LIVE) {
          Footer_Menu_Block {
            Label
            Url {
              Alias
            }
            Sub_Footer_menu {
              Label
              Url {
                Alias
              }
            }
          }
        }
        clientLogo {
          Publish
          Clients {
            Client_Url
            Client_Image {
              alternativeText
              url
            }
          }
        }
        globalConfig(publicationState: LIVE) {
          Facebook
          Instagram
          LinkedIn
          Twitter
          Youtube
        }
      }
    }
  `)
  const [search, setSearch] = useState(false)
  const [state, setState] = useState(null)

  const openSearch = () => {
    setSearch(!search)
  }

  const clickHandler = index => {
    if (state === index) {
      setState(null)
    } else {
      setState(index)
    }
  }
  const {  pathname } = useLocation()
   
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm="12">
            <div className="content-wrapper">
              <div className="footer-links">
                {/* {JSON.stringify(data.glstrapi?.Footer_Menu_Block)} */}
                {data.glstrapi?.menuFooter?.Footer_Menu_Block?.map(
                  (item, i) => (
                    <FooterMenu
                      i={i}
                      clickHandler={clickHandler}
                      // {...item}
                      Label={item.Label}
                      Url={item.Url}
                      state={state}
                      Sub_Footer_menu={item.Sub_Footer_menu}
                    />
                  )
                )}
              </div>

              <div className="footer-info">
                {data.glstrapi?.globalConfig && (
                  <div className="social-links">
                    {data.glstrapi?.globalConfig?.LinkedIn && (
                      <Link
                        to={data.glstrapi?.globalConfig?.LinkedIn}
                        target="_blank"
                      >
                        <i className="icon-linkedin"></i>
                      </Link>
                    )}
                    {data.glstrapi?.globalConfig?.Instagram && (
                      <Link
                        to={data.glstrapi?.globalConfig?.Instagram}
                        target="_blank"
                      >
                        <i className="icon-instagram"></i>
                      </Link>
                    )}
                    {data.glstrapi?.globalConfig?.Facebook && (
                      <Link
                        to={data.glstrapi?.globalConfig?.Facebook}
                        target="_blank"
                      >
                        <i className="icon-facebook"></i>
                      </Link>
                    )}
                    {data.glstrapi?.globalConfig?.Twitter && (
                      <Link
                        to={data.glstrapi?.globalConfig?.Twitter}
                        target="_blank"
                      >
                        <i className="icon-twitter"></i>
                      </Link>
                    )}
                    {data.glstrapi?.globalConfig?.Youtube && (
                      <Link
                        to={data.glstrapi?.globalConfig?.Youtube}
                        target="_blank"
                      >
                        <i className="icon-youtube"></i>
                      </Link>
                    )}
                  </div>
                )}
                <div className="copyright-info">
                  &copy; {year} DNG Group Limited.
                  <br />
                  All Rights Reserved.
                </div>
                <div className="site-by">
                  Site by{" "}
                  <a target="_blank" href="https://starberry.tv/">
                    <img src={starberryLogo} alt="starberry" />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-logos">
              {data.glstrapi?.clientLogo?.Clients?.map((item, i) => (
                <div className="logo-wrap rics-logo">
                  <Link to={item.Client_Url}>
                    <img
                      src={item.Client_Image.url}
                      alt={
                        item.Client_Image.alternativeText +
                        " - DNG Estate Agents"
                      }
                    />
                  </Link>
                </div>
                // <div className="logo-wrap rics-logo">
                // 	<Link to="/"><img src={ricsLogo} alt="risc" /></Link>
                // </div>
                // <div className="logo-wrap scs-logo">
                // 	<Link to="/"><img src={scsLogo} alt="scs" /></Link>
                // </div>
              ))}
            </div>
            {pathname === "/" &&
            <StickyHome/>}
          </Col>
        </Row>
        {/* <CookieConsent
          disableStyles={true}
          buttonClasses="btn btn-primary btn-link gotit"
          containerClasses="cookie-popup d-lg-flex d-block"
          contentClasses=""
          buttonText="Accept Cookies"
        >
          <p className="mb-0 text-center">
            We have placed cookies on your device to help make this website
            better. By continuing, you agree to our{" "}
            <Link to="/cookie-policy">Cookie Policy</Link>.
          </p>
        </CookieConsent> */}   
      </Container>
    </footer>
  )
}

export default Footer
