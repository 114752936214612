import React from "react";
import "../StickyHome/StickyHome.scss"

const StickyHome = () =>{
    return(
        <div className="sticky-home-btn">
             <a href="/book-a-valuation/" className="btn">
                     Book a valuation      
             </a>
        </div>
    )
}
export default StickyHome