export const inViewOptions = {
    // threshold: 0.3,
    rootMargin: '-100px 0px',
    triggerOnce: true,
}
export const inViewOptions1 = {
    // threshold: 0.3,
    rootMargin: '-100px 0px',
    triggerOnce: false,
}

export const easeFunc = {
    ease: [0.17, 0.67, 0.35, 0.99]
}
export const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
    }
}
export const fadeInFromRight = {
    hidden: { opacity: 0, x: 50 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ...easeFunc,
            duration: 1.4
        }
    }
}
export const fadeInFromLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            ...easeFunc,
            duration: 1.4
        }
    }
}
export const fadeInFromBottom = {
    hidden: { opacity: 0, y: -50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ...easeFunc,
            duration: 1.2
        }
    }
}
export const fadeInFromTop = {
    hidden: { opacity: 0, y: 50 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            ...easeFunc,
            duration: 1.2
        }
    }
}
export const delayItems = {
    hidden: { opacity: 0, x: -50 },
    visible: (custom) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: custom * 0.2,
            ...easeFunc,
            duration: 0.4,
        }
    })
}
export const delayItemsFromBottom = {
    hidden: { opacity: 0, y: -50 },
    visible: (custom) => ({
        opacity: 1,
        y: 0,
        transition: {
            delay: custom * 0.2,
            ...easeFunc,
            duration: 0.6,
        }
    })
}
export const delayMenuItems = {
    hidden: { opacity: 0, x: -30 },
    visible: (custom) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: custom * 0.1,
            ...easeFunc,
            duration: 0.2,
        }
    })
}
export const bgImageZoom = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            ...easeFunc,
            duration: 1.2,
        }
    }
}
export const imageZoom = {
    hidden: { opacity: 0, scale: 1.2 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            ...easeFunc,
            duration: 1.2,
        }
    }
}
export const contentItemStagger = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            delayChildren: -0.25,
            ...easeFunc,
            duration: 1.2
        }
    }
}
export const contentItem = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
}