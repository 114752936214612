import React,{useState, useEffect} from 'react'
import {
    Button,
    Form,
    Container,
    FormControl,
    Row,
    InputGroup,
    Col
  } from "react-bootstrap";
  import { Link } from "gatsby";
  import GetURL from '@Components/common/site/get-url';
const FooterMenu=(props)=>{

   

    return (
        <>
               
                  <div className="menu-wrap">
                 
                      <div onClick={()=>props.clickHandler(props.i)} 
                       className={`menu-heading  ${props.i === props.state ? `minus` : `plus`}`}>
                        <span>{props.Label}</span>
                        <i className="icon-minus-footer"></i>
                        <i className="icon-plus-footer"></i>
                      </div>
                     
                        <ul className={`footer-menu  ${props.i === props.state ? `` : `hide`}`}>
                            {
                                props.Sub_Footer_menu?.map((item,i)=>
                                    <li><GetURL label={item.Label} alias={item.Url?.Alias} /></li>
                                )
                            }
                        </ul>
                      
                   
                  </div>
                
        </>
    )
}
export default FooterMenu